import { db } from '../../db/firestore';
import { doc, getDoc } from "firebase/firestore";
import {searchAlgolia} from '../../db/algolia';

const buildLegisTitle = (item) => {
    const chamberType = {
        1: "H",
        2: "S",
    };
    const documentType = {
        1: "B",
        2: "R",
    };
    return `${chamberType[item.chamber]}${documentType[item.documentType]}${item.number}`;
}

const getKeywordBills = async (data) => {
    const dataArr = [];
    if (data) {
        const { hits } = await searchAlgolia(data.includes(' ') ? `"${data}"` : `${data}`)
        for (let i = 0; i < hits.length; i += 1) {
            const bill = hits[i]
            const legislationTitle = buildLegisTitle(bill)
            dataArr.push({
                ...bill,
                legislationTitle,
            })
        }
        return dataArr
    }
}

const getCommitteeBills = async (data) => {
    const dataArr = [];
    const filterArr = [];
    if (data?.chamber) {
        const filters = [`committees.id:${data.id}`]
        const { hits } = await searchAlgolia('', undefined, filters)
        filterArr.push(...hits)
        for (let i = 0; i < filterArr.length; i += 1) {
            const bill = filterArr[i]
            const legislationTitle = buildLegisTitle(bill)
            dataArr.push({
                ...bill,
                legislationTitle,
            })
        }
    }
    return dataArr
}

const getClientBills = (email, clientList) => {
    // Find the client with the matching email
    const client = clientList.find(client => client.email === email);
    
    // If the client is not found, return an empty array
    if (!client) {
        return [];
    }

    // Map the client's bills to the desired format
    return client.bills.map(bill => ({
        id: bill.billId,
        legislationTitle: bill.billName,
        title: bill.billSubject
    }));
}

const getMoreBills = (currentData, numRendered) => {
    if (currentData.length === numRendered) {
        return
    } else {
        return currentData.slice(0, numRendered + 20)
    }
}

export {
    getKeywordBills,
    getCommitteeBills,
    getClientBills,
    getMoreBills,
}
