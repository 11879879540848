import { jsPDF } from "jspdf";
import { storage } from "../../db/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";

const cleanText = (text) => {
    if (Array.isArray(text)) {
        text = text.join("\n");
    }
    return text
        .replace(/ {8,}/g, "\n")
        .replace(/ +/g, " ")
        .trim();
};


export const generateBillPDF = async (bill, billName, selectedFields, billPriority, billIssue, clientNotes, billNote, billLink, clientEmail) => {
    const doc = new jsPDF();
    const marginLeft = 10;
    const lineSpacing = 5; // Reduced line spacing to make it tighter
    const pageHeight = doc.internal.pageSize.height;
    let currentY = 20;

    const checkPageOverflow = () => {
        if (currentY + lineSpacing > pageHeight - 10) {
            doc.addPage();
            currentY = 20; // Reset currentY for the new page
        }
    };

    // Header
    doc.setFont("helvetica", "bold");
    doc.setFontSize(18);
    doc.text(billName, marginLeft, currentY); // Just print the bill name in the header, title will be in next section
    doc.setFontSize(10);
    doc.text(`Date: ${new Date().toLocaleDateString()}`, 180, currentY, { align: "right" });

    currentY += lineSpacing + 5;

    // Bill Title (wrap it if too long)
    doc.setFont("helvetica", "bold");
    doc.setFontSize(14);
    const titleLines = doc.splitTextToSize(bill.title, 180); // Split title if it's too long
    doc.text(titleLines, marginLeft, currentY);
    currentY += titleLines.length * lineSpacing;

    // Bill Description
    doc.setFontSize(10);
    currentY += 3; // Reduced gap after the title
    const descriptionLines = doc.splitTextToSize(bill.firstReader || "No description available.", 180);
    doc.text(descriptionLines, marginLeft, currentY);
    currentY += descriptionLines.length * lineSpacing;

    // Add "View Full Bill" link under the description
    const linkText = "View Full Bill";
    doc.setTextColor(0, 0, 255); // Set the text color to blue
    doc.textWithLink(linkText, marginLeft, currentY, { url: billLink }); // Add the clickable link
    currentY += lineSpacing + 3; // Add space after the link
    doc.setTextColor(0, 0, 0);

    // Bill Priority
    if (billPriority && selectedFields.includes("billPriority")) {
        // Remove the extra margin before Priority and align it with the rest
        // Set background color based on priority
        if (billPriority === "High") {
            doc.setFillColor(255, 230, 230); // Light Red
        } else if (billPriority === "Moderate") {
            doc.setFillColor(255, 255, 204); // Light Yellow
        } else if (billPriority === "Low") {
            doc.setFillColor(204, 255, 255); // Light Blue
        }

        doc.rect(marginLeft, currentY, 180, lineSpacing + 2, 'F'); // Fill the background
        doc.setFontSize(12);
        doc.text(`Priority: ${billPriority}`, marginLeft, currentY + 5); // Adding some padding to the text
        currentY += lineSpacing + 7; // Reduced gap after Priority field
        checkPageOverflow();
    }

    // Bill Issue
    if (billIssue && selectedFields.includes("billIssue")) {
        doc.setFontSize(12);
        const issueText = billIssue.toUpperCase(); // Capitalize the issue text
        doc.text(`Issue: ${issueText}`, marginLeft, currentY);
        currentY += lineSpacing + 5; // Reduced gap after Issue field
        checkPageOverflow();
    }

    // Notes (if selected)
    if (billNote && selectedFields.includes("billNotes")) {
        doc.setFontSize(12);
        doc.text("Notes:", marginLeft, currentY);
        currentY += lineSpacing + 2;
        doc.setFontSize(10);
        const noteLines = doc.splitTextToSize(billNote || "No notes available.", 180);
        noteLines.forEach((line) => {
            checkPageOverflow(); // Check if the content fits the current page
            doc.text(line, marginLeft, currentY);
            currentY += lineSpacing + 1;
        });
        currentY += lineSpacing + 5;
    }

    // Client Notes (if selected)
    if (clientNotes && selectedFields.includes("clientNotes")) {
        doc.setFontSize(12);
        doc.text("Client Notes:", marginLeft, currentY);
        currentY += lineSpacing + 2;
        doc.setFontSize(10);
        const noteLines = doc.splitTextToSize(clientNotes || "No notes available.", 180);
        noteLines.forEach((line) => {
            checkPageOverflow(); // Check if the content fits the current page
            doc.text(line, marginLeft, currentY);
            currentY += lineSpacing + 1;
        });
        currentY += lineSpacing + 5;
    }

    // Bill History (if selected)
    if (selectedFields.includes("billHistory")) {
        doc.setFontSize(12);
        doc.text("Bill History:", marginLeft, currentY);
        currentY += lineSpacing + 2;
        doc.setFontSize(10);
        if (bill.billHistory?.length > 0) {
            bill.billHistory.forEach((history, index) => {
                checkPageOverflow(); // Check if the content fits the current page
                doc.text(
                    `${index + 1}. ${history.type} (${new Date(history.date).toLocaleDateString()})`,
                    marginLeft,
                    currentY
                );
                currentY += lineSpacing;

                if (history.type === "Calendar" && history.name) {
                    checkPageOverflow(); // Check if the content fits the current page
                    doc.text(
                        `${history.name}`,
                        marginLeft + 4,
                        currentY
                    );
                    currentY += lineSpacing;
                } else if (history.type === "Meeting" && history.committees?.length > 0) {
                    history.committees.forEach((committee) => {
                        checkPageOverflow(); // Check if the content fits the current page
                        doc.text(
                            `${committee}`,
                            marginLeft + 4, // Indent to differentiate from the type line
                            currentY
                        );
                        currentY += lineSpacing;
                    });
                }
                currentY += lineSpacing;
            });
        } else {
            checkPageOverflow();
            doc.text('No Bill History To Show', marginLeft, currentY);
            currentY += 5;
        }
        currentY += lineSpacing;
    }

    // Sponsors (if selected)
    if (selectedFields.includes("sponsors")) {
        doc.setFontSize(12);
        doc.text("Sponsors:", marginLeft, currentY);
        currentY += lineSpacing + 2;
        doc.setFontSize(10);
        if (bill.sponsors?.length > 0) {
            bill.sponsors?.forEach((sponsor, index) => {
                checkPageOverflow(); // Check if the content fits the current page
                doc.text(
                    `${index + 1}. ${sponsor.name} (${sponsor.district})`,
                    marginLeft,
                    currentY
                );
                currentY += lineSpacing;
            });
        } else {
            checkPageOverflow();
            doc.text('No Sponsors To Show', marginLeft, currentY);
            currentY += 5;
        }
        currentY += lineSpacing;
    }

    // Status History (if selected)
    if (selectedFields.includes("statusHistory")) {
        doc.setFontSize(12);
        doc.text("Status History:", marginLeft, currentY);
        currentY += lineSpacing + 2;
        doc.setFontSize(10);
        if (bill.statusHistory?.length > 0) {
            bill.statusHistory?.forEach((status, index) => {
                checkPageOverflow(); // Check if the content fits the current page
                doc.text(
                    `${index + 1}. ${status.name} (${new Date(status.date).toLocaleDateString()})`,
                    marginLeft,
                    currentY
                );
                currentY += lineSpacing;
            });
        } else {
            checkPageOverflow();
            doc.text('No Status History To Show', marginLeft, currentY);
            currentY += 5;
        }
        currentY += lineSpacing;
    }

    // Committees (if selected)
    if (selectedFields.includes("committees")) {
        doc.setFontSize(12);
        doc.text("Committees:", marginLeft, currentY);
        currentY += lineSpacing + 2;
        doc.setFontSize(10);
        if (bill.committees?.length > 0) {
            bill.committees?.forEach((committee, index) => {
                checkPageOverflow(); // Check if the content fits the current page
                doc.text(`${index + 1}. ${committee.name}`, marginLeft, currentY);
                currentY += lineSpacing;
            });
        } else {
            checkPageOverflow();
            doc.text('No Committees Assigned', marginLeft, currentY);
            currentY += 5;
        }
        currentY += lineSpacing;
    }

    // Votes (if selected)
    if (selectedFields.includes("votes")) {
        doc.setFontSize(12);
        doc.text("Votes:", marginLeft, currentY);
        currentY += lineSpacing + 2;
        doc.setFontSize(10);
        if (bill.votes?.length > 0) {
            bill.votes?.forEach((vote, index) => {
                checkPageOverflow(); // Check if the content fits the current page
                doc.text(
                    `${index + 1}. ${vote.name}: Yea - ${vote.yea}, Nay - ${vote.nay}, Not Voting - ${vote.notVoting}, Excused - ${vote.excused}`,
                    marginLeft,
                    currentY,
                    { maxWidth: 180 }
                );
                currentY += lineSpacing;
            });
        } else {
            checkPageOverflow();
            doc.text('No Votes Available', marginLeft, currentY);
            currentY += 5;
        }
        currentY += lineSpacing;
    }

    // Footnotes (if selected)
    if (selectedFields.includes("footnotes")) {
        doc.setFontSize(12);
        doc.text("Footnotes:", marginLeft, currentY);
        currentY += lineSpacing + 2;

        // Set font size for the footnotes text
        doc.setFontSize(10);

        // Split the footnotes string by "; "
        const footnotesArray = bill.footnotes
            ? bill.footnotes.split("; ") // Split by semicolon and space
            : ["No footnotes available."];

        // Loop through each footnote and add it to the PDF
        footnotesArray.forEach((footnote, index) => {
            // Remove leading/trailing spaces
            footnote = footnote.trim();
            if (footnote) {
                checkPageOverflow(); // Check if the content fits the current page
                doc.text(`${index + 1}. ${footnote}`, marginLeft, currentY); // Add the footnote to the PDF
                currentY += lineSpacing;
            }
        });

        currentY += lineSpacing + 5;
    }

    const analysisFields = ["intent", "rDisagree", "dDisagree", "rQuestions", "dQuestions"];
    const isAnalysisIncluded = selectedFields.some((field) => analysisFields.includes(field));

    if (isAnalysisIncluded) {
        doc.addPage(); // Add a new page for "Bill Analysis"
        currentY = 20;

        doc.setFontSize(14);
        doc.text("Bill Analysis", marginLeft, currentY);
        currentY += lineSpacing * 2;

        // Add each analysis field dynamically
        const fieldMappings = {
            intent: "Intent",
            rDisagree: "Why would Republicans disagree with this Legislation",
            dDisagree: "Why would Democrats disagree with this Legislation",
            rQuestions: "Republican questions for their senators/representatives",
            dQuestions: "Democrat questions for their senators/representatives",
        };

        analysisFields.forEach((field) => {
            if (selectedFields.includes(field)) {
                // Increase font size for the field label
                doc.setFontSize(12);
                doc.text(fieldMappings[field] + ":", marginLeft, currentY);
                currentY += lineSpacing + 2;

                // Use a smaller font size for the content
                doc.setFontSize(10);
                const contentLines = doc.splitTextToSize(
                    cleanText(bill.analysis[field] || "No information provided."),
                    180
                );
                contentLines.forEach((line) => {
                    checkPageOverflow(); // Check if the content fits the current page
                    doc.text(line, marginLeft, currentY);
                    currentY += lineSpacing;
                });

                currentY += lineSpacing; // Small gap before the next field
            }
        });
    }

    if (clientEmail) {
        const pdfBlob = doc.output("blob");
        const storageRef = ref(storage, `billReports/${billName}_${clientEmail}`);
        // Upload PDF to Firebase Storage
        await uploadBytes(storageRef, pdfBlob);

        // Get the download URL
        const downloadURL = await getDownloadURL(storageRef);
        createExportEmail(downloadURL, clientEmail, billName)
    } else {
        doc.save(`${billName || "Bill_Report"}.pdf`);
    }

};

const createExportEmail = async (pdfUrl, clientEmail, billName) => {
    const subject = encodeURIComponent(`${billName} Report`);
    const body = encodeURIComponent(
        `Your legislation report is available at the following link:

${pdfUrl}

Please note: This link will only remain active for 7 days. If you wish to retain this report for your records, we recommend downloading it before the link expires.`
    );

    const mailtoLink = `mailto:${clientEmail}?subject=${subject}&body=${body}`;

    // Open the mailto link
    window.location.href = mailtoLink;
};