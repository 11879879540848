import React, { useState, useEffect } from 'react';
import { useHeaderHeight } from '@react-navigation/elements';
import constants from "../../utils/constants";
import { StyleSheet, ActivityIndicator, Text, View, Pressable, FlatList, Platform } from 'react-native'
import { Dropdown } from 'react-native-element-dropdown';
import { SearchBar } from 'react-native-elements';
import { Ionicons, MaterialCommunityIcons, AntDesign, Foundation } from '@expo/vector-icons';
import {
    getKeywordBills,
    getCommitteeBills,
    getClientBills,
    getMoreBills,
} from './billHelper'

const chamberObj = {
    1: "House",
    2: "Senate",
}

const BillTable = ({ route, navigation, userInfo }) => {
    const [billTable, setBillTable] = useState([]);
    const [isBillFocus, setIsBillFocus] = useState(false);
    const [isWordFocus, setIsWordFocus] = useState(false);
    const [isCommitteeFocus, setIsCommitteeFocus] = useState(false);
    const [isClientFocus, setIsClientFocus] = useState(false);
    const [billValue, setBillValue] = useState('1');
    const [wordValue, setWordValue] = useState('1');
    const [committeeValue, setCommitteeValue] = useState('1');
    const [clientValue, setClientValue] = useState('1');
    const [clientEmail, setClientEmail] = useState('');
    const [currentUserInfo, setCurrentUserInfo] = useState();
    const [currentData, setCurrentData] = useState();
    const [keywords, setKeywords] = useState([]);
    const [committees, setCommittees] = useState([]);
    const [clients, setClients] = useState([]);
    const [pageLoading, setPageLoading] = useState(true);
    const [searchQuery, setSearchQuery] = useState('');
    const [searchData, setSearchData] = useState([]);
    const headerHeight = useHeaderHeight();

    const billOptions = [
        { label: 'Tracked Bills', value: '1' },
        { label: 'Tracked Language', value: '2' },
        { label: 'Legislation Assigned to Tracked Committee', value: '3' },
        { label: 'Legislation Assigned to Client', value: '4' },
    ];

    const changeBillValue = async () => {
        setPageLoading(true);
        let data = null;
        if (billValue === '1') {
            data = userInfo.trackedLegislation;
        } else if (billValue === '2') {
            const word = keywords?.find(item => item.value === wordValue)?.label;
            data = await getKeywordBills(word);
        } else if (billValue === '3') {
            const committee = committees?.find(item => item.value === committeeValue);
            const committeeObj = userInfo.trackedCommittees.find(item => {
                const itemName = `${item.name} (${chamberObj[item.chamber]})`;
                return itemName === committee?.label;
            });
            data = await getCommitteeBills(committeeObj);
        } else if (billValue === '4') {
            // const word = keywords?.find(item => item.value === wordValue)?.label;
            data = getClientBills(clientEmail, userInfo.clientList);
        }

        setCurrentData(data);
        setBillTable(data?.slice(0, 20) || []);
        setPageLoading(false);
    };

    const renderElement = (item) => {
        return (
            <Pressable
                key={item.id}
                style={[styles.button, styles.buttonClose]}
                onPress={() => navigation.navigate('Bill', { title: item.legislationTitle, billId: item.id, tier: userInfo.tier, options: { headerBackTitleVisible: false } })}
            >
                <View style={styles.rowContainer}>
                    <View style={{ flex: 1.2 }}>
                        <Text style={styles.textStyle}>{item.legislationTitle}</Text>
                    </View>
                    <View style={{ flex: 5 }}>
                        <Text style={styles.subTextStyle}>{item.title}</Text>
                    </View>
                    <View style={{ flex: .5 }}>
                        <MaterialCommunityIcons name="dots-vertical" size={26} color={constants.ICON_COLOR} />
                    </View>
                </View>
            </Pressable>
        )
    }

    useEffect(() => {
        // Filtering logic based on searchQuery
        const filteredData = currentData?.filter(item => {
            const hasMatchingTitle = item.legislationTitle?.toLowerCase().includes(searchQuery.toLowerCase());
            const hasMatchingSubtitle = item.title?.toLowerCase().includes(searchQuery.toLowerCase());
            return hasMatchingTitle || hasMatchingSubtitle;
        }) || [];
        setSearchData(filteredData)
        // Update the bill table with the filtered data based on the selected bill option
        if (billValue === '1') {
            // Tracked Bills option
            setBillTable(filteredData.slice(0, 20));
        } else if (billValue === '2') {
            // Tracked Language option
            setBillTable(filteredData.slice(0, 20));
        } else if (billValue === '3') {
            // Legislation Assigned to Tracked Committee option
            setBillTable(filteredData.slice(0, 20));
        } else if (billValue === '4') {
            // Legislation Assigned to Client option
            setBillTable(filteredData.slice(0, 20));
        }
    }, [searchQuery, currentData, billValue]);

    useEffect(() => {
        changeBillValue()
        if ((userInfo.trackedWords.length !== currentUserInfo?.trackedWords.length) ||
            (userInfo.trackedCommittees.length !== currentUserInfo?.trackedCommittees.length) ||
            (userInfo.trackedLegislation.length !== currentUserInfo?.trackedLegislation.length)
        ) {
            const wordArr = [];
            const committeeArr = [];
            const clientArr = [];
            if (userInfo?.trackedWords) {
                for (let i = 0; i < userInfo.trackedWords.length; i += 1) {
                    wordArr.push({ label: userInfo.trackedWords[i], value: i + 1 })
                }
            }
            if (userInfo?.trackedCommittees) {
                for (let i = 0; i < userInfo.trackedCommittees.length; i += 1) {
                    committeeArr.push({
                        label: `${userInfo.trackedCommittees[i].name} (${chamberObj[userInfo.trackedCommittees[i].chamber]})`,
                        chamber: userInfo.trackedCommittees[i].chamber,
                        value: i + 1
                    })
                }
            }
            if (userInfo?.clientList) {
                for (let i = 0; i < userInfo.clientList.length; i += 1) {
                    clientArr.push({ 
                        label: `${userInfo.clientList[i].name} (${userInfo.clientList[i].email})`,
                        email:  userInfo.clientList[i].email,
                        value: i + 1 
                    })
                }
            }
            setKeywords(wordArr)
            setCommittees(committeeArr)
            setClients(clientArr)
            setCurrentUserInfo(userInfo)
        }
    }, [userInfo, billValue, wordValue, committeeValue, clientValue])

    return (
        <View style={{ height: '100%', backgroundColor: constants.PRIMARY_DARK }}>
            <View style={[styles.container, { marginTop: headerHeight + 10 }]}>
                <View style={styles.trackedButtons}>
                    <Dropdown
                        style={[styles.dropdown, isBillFocus && { borderColor: 'blue' }]}
                        placeholderStyle={styles.dropdownTextStyle}
                        selectedTextStyle={styles.dropdownTextStyle}
                        fontFamily='Verdana'
                        containerStyle={{ backgroundColor: '#242424', borderRadius: 9 }}
                        itemContainerStyle={{ backgroundColor: '#242424', borderRadius: 9 }}
                        itemTextStyle={{ color: 'white', fontFamily: 'Verdana' }}
                        activeColor='#3f3f3f'
                        inputSearchStyle={{ color: 'white', borderRadius: 9, fontFamily: 'Verdana' }}
                        iconStyle={styles.iconStyle}
                        data={billOptions}
                        maxHeight={300}
                        labelField="label"
                        valueField="value"
                        placeholder={!isBillFocus ? 'Tracked Bills' : '...'}
                        searchPlaceholder="Search..."
                        value={billValue}
                        renderLeftIcon={() => (
                            <Foundation style={styles.icon} name="page-search" size={18} color={constants.ICON_COLOR} />
                        )}
                        onFocus={() => setIsBillFocus(true)}
                        onBlur={() => setIsBillFocus(false)}
                        onChange={item => {
                            setBillValue(item.value);
                            setIsBillFocus(false);
                        }}
                    />
                </View>
                <View style={styles.trackedButtons}>
                    {billValue === '2' ?
                        <Dropdown
                            style={[styles.dropdown, isWordFocus && { borderColor: 'blue' }]}
                            placeholderStyle={styles.dropdownTextStyle}
                            selectedTextStyle={styles.dropdownTextStyle}
                            fontFamily='Verdana'
                            containerStyle={{ backgroundColor: '#242424', borderRadius: 9 }}
                            itemContainerStyle={{ backgroundColor: '#242424', borderRadius: 9 }}
                            itemTextStyle={{ color: 'white', fontFamily: 'Verdana' }}
                            activeColor='#3f3f3f'
                            inputSearchStyle={{ color: 'white', borderRadius: 9, fontFamily: 'Verdana' }}
                            iconStyle={styles.iconStyle}
                            data={keywords}
                            search
                            maxHeight={300}
                            labelField="label"
                            valueField="value"
                            placeholder={!isWordFocus ? 'Choose Your Keyword' : '...'}
                            searchPlaceholder="Search..."
                            value={wordValue}
                            renderLeftIcon={() => (
                                <Ionicons style={styles.icon} name="text" size={18} color={constants.ICON_COLOR} />
                            )}
                            onFocus={() => setIsWordFocus(true)}
                            onBlur={() => setIsWordFocus(false)}
                            onChange={item => {
                                setWordValue(item.value);
                                setIsWordFocus(false);
                            }}
                        />
                        : null}
                </View>
                <View style={styles.trackedButtons}>
                    {billValue === '3' ?
                        <Dropdown
                            style={[styles.dropdown, isCommitteeFocus && { borderColor: 'blue' }]}
                            placeholderStyle={styles.dropdownTextStyle}
                            selectedTextStyle={styles.dropdownTextStyle}
                            fontFamily='Verdana'
                            containerStyle={{ backgroundColor: '#242424', borderRadius: 9 }}
                            itemContainerStyle={{ backgroundColor: '#242424', borderRadius: 9 }}
                            itemTextStyle={{ color: 'white', fontFamily: 'Verdana' }}
                            activeColor='#3f3f3f'
                            inputSearchStyle={{ color: 'white', borderRadius: 9, fontFamily: 'Verdana' }}
                            iconStyle={styles.iconStyle}
                            data={committees}
                            search
                            maxHeight={300}
                            labelField="label"
                            valueField="value"
                            placeholder={!isCommitteeFocus ? 'Choose Your Committee' : '...'}
                            searchPlaceholder="Search..."
                            value={committeeValue}
                            renderLeftIcon={() => (
                                <AntDesign style={styles.icon} name="team" size={18} color={constants.ICON_COLOR} />
                            )}
                            onFocus={() => setIsCommitteeFocus(true)}
                            onBlur={() => setIsCommitteeFocus(false)}
                            onChange={item => {
                                setCommitteeValue(item.value);
                                setIsCommitteeFocus(false);
                            }}
                        />
                        : null}
                </View>
                <View style={styles.trackedButtons}>
                    {billValue === '4' ?
                        <Dropdown
                            style={[styles.dropdown, isClientFocus && { borderColor: 'blue' }]}
                            placeholderStyle={styles.dropdownTextStyle}
                            selectedTextStyle={styles.dropdownTextStyle}
                            fontFamily='Verdana'
                            containerStyle={{ backgroundColor: '#242424', borderRadius: 9 }}
                            itemContainerStyle={{ backgroundColor: '#242424', borderRadius: 9 }}
                            itemTextStyle={{ color: 'white', fontFamily: 'Verdana' }}
                            activeColor='#3f3f3f'
                            inputSearchStyle={{ color: 'white', borderRadius: 9, fontFamily: 'Verdana' }}
                            iconStyle={styles.iconStyle}
                            data={clients}
                            search
                            maxHeight={300}
                            labelField="label"
                            valueField="value"
                            placeholder={!isClientFocus ? 'Choose Your Client' : '...'}
                            searchPlaceholder="Search..."
                            value={clientValue}
                            renderLeftIcon={() => (
                                <AntDesign style={styles.icon} name="user" size={18} color={constants.ICON_COLOR} />
                            )}
                            onFocus={() => setIsClientFocus(true)}
                            onBlur={() => setIsClientFocus(false)}
                            onChange={item => {
                                setClientEmail(item.email)
                                setClientValue(item.value);
                                setIsClientFocus(false);
                            }}
                        />
                        : null}
                </View>
                <SearchBar
                    placeholder="Search Legislation"
                    onChangeText={(text) => setSearchQuery(text)}
                    value={searchQuery}
                    clearIcon
                    darkTheme
                    containerStyle={{ backgroundColor: constants.PRIMARY_DARK, width: '98%', alignSelf: 'center', padding: 0, marginBottom: 15 }}
                    inputContainerStyle={{ borderRadius: 9 }}
                    inputStyle={{ color: 'white' }}
                />
                <View style={{ flex: 1 }}>
                    {pageLoading ?
                        <ActivityIndicator size="large" color={constants.ICON_COLOR} />
                        :
                        <FlatList
                            style={billValue === '1' ? styles.trackedScrollView : styles.scrollView}
                            data={billTable}
                            renderItem={({ item }) => renderElement(item)}
                            keyExtractor={item => item.id}
                            onEndReached={() => {
                                if (searchData.length > 20 && searchData.length !== billTable.length) {
                                    const results = getMoreBills(searchData, billTable.length);
                                    setBillTable(results)
                                }
                            }}
                            onEndReachedThreshold={0.01}
                            scrollEventThrottle={150}
                        />
                    }
                </View>
            </View>
        </View>
    )
}

export default BillTable

const styles = StyleSheet.create({
    container: {
        backgroundColor: constants.PRIMARY_DARK,
        flex: 1,
        fontFamily: 'Verdana',
    },
    dropdown: {
        backgroundColor: '#242424',
        height: 40,
        width: '98%',
        borderColor: 'gray',
        borderWidth: 0.5,
        borderRadius: 9,
        paddingHorizontal: 8,
        marginBottom: 10,
    },
    dropdownTextStyle: {
        color: '#e5e5e5',
        ...Platform.select({
            ios: {
                fontSize: 16,
            },
            android: {
                fontSize: 14,
            }
        })
    },
    iconStyle: {
        width: 20,
        height: 20,
    },
    icon: {
        marginRight: 7,
    },
    listItems: {
        backgroundColor: "#D3D3D3",
        borderWidth: 1,
        marginLeft: 15,
        marginRight: 15,
        marginBottom: 6,
        fontFamily: 'Verdana',
    },
    trackedScrollView: {
        // marginBottom: -65
    },
    scrollView: {
        // marginBottom: 20,
        // flexGrow: 1
    },
    scrollContainer: {
        // height: 750,
        // paddingBottom: 50,
    },
    rowContainer: {
        flexDirection: 'row',
        width: "100%",
        alignItems: 'center'
    },
    button: {
        minHeight: 60,
        borderRadius: 9,
        borderWidth: 3,
        borderColor: 'black',
        paddingTop: 14,
        paddingBottom: 14,
        paddingLeft: 3,
        paddingRight: 3,
        elevation: 2,
        alignSelf: 'center',
        width: "98%",
        flexGrow: 1,
        marginBottom: 1,
    },
    trackedButtons: {
        flexDirection: 'row',
        justifyContent: 'center',
    },
    buttonOpen: {
        backgroundColor: "white",
    },
    buttonClose: {
        backgroundColor: constants.SECONDARY_DARK,
    },
    textStyle: {
        color: constants.TITLE_COLOR,
        opacity: .7,
        fontSize: 14.5,
        fontWeight: "bold",
        fontFamily: 'Verdana',
        alignSelf: "center",
    },
    subTextStyle: {
        flexGrow: 0,
        color: constants.SUBTEXT_COLOR,
        width: "90%",
        marginLeft: 18,
        fontSize: 14,
        fontFamily: 'Verdana',
        ...Platform.select({
            ios: {
                lineHeight: 20
            },
            android: {}
        })
    },
})
