import React, { useEffect, useState } from 'react';
import { useHeaderHeight } from '@react-navigation/elements';
import constants from "../../utils/constants";
import { StyleSheet, Text, View, Pressable, ScrollView } from 'react-native';
import { AntDesign, MaterialIcons } from '@expo/vector-icons';

const AIAnalysis = ({ route, navigation, userInfo }) => {
  const { billData, title } = route.params
  const [currentIndex, setCurrentIndex] = useState(null);
  const headerHeight = useHeaderHeight();

  const cleanText = (text) => {
    if (Array.isArray(text)) {
      text = text.join("\n");
    }
    return text
      .replace(/ {8,}/g, "\n")
      .replace(/ +/g, " ")
      .trim();
  };

  const data = [
    {
      question: "Bill's intent",
      answer: cleanText(billData.analysis?.intent),
    },
    {
      question: 'Why would Republicans disagree with this bill?',
      answer: cleanText(billData.analysis?.rDisagree),
    },
    {
      question: 'Why would Democrats disagree with the bill?',
      answer: cleanText(billData.analysis?.dDisagree),
    },
    {
      question: 'Anything that might seem deceiving to an average person?',
      answer: cleanText(billData.analysis?.deceiving),
    },
    {
      question: 'Republican questions for their senators/representatives',
      answer: cleanText(billData.analysis?.rQuestions),
    },
    {
      question: 'Democrat questions for their senators/representatives',
      answer: cleanText(billData.analysis?.dQuestions),
    },
  ];

  return (
    <View style={{ height: '100%', backgroundColor: constants.PRIMARY_DARK }}>
      <View style={[styles.container, { marginTop: headerHeight + 10 }]} >
        <ScrollView style={styles.scrollView}>
          {data.map(({ question, answer }, index) => {
            return (
              <View key={question}>
                <Pressable
                  onPress={() => {
                    setCurrentIndex(index === currentIndex ? null : index);
                  }}
                  style={[styles.card, styles.buttonClose]}
                  activeOpacity={0.9}
                >
                  <View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 10 }}>
                    <MaterialIcons name="notes" size={24} color={constants.ICON_COLOR} />
                    <View style={{ marginLeft: 10, width: '80%' }}>
                      <Text style={[styles.textStyle]}>{question}</Text>
                    </View>
                    {index === currentIndex ?
                      <AntDesign name="minus" size={24} color={constants.ICON_COLOR} style={{ flex: 1, textAlign: 'right' }} />
                      :
                      <AntDesign name="plus" size={24} color={constants.ICON_COLOR} style={{ flex: 1, textAlign: 'right' }} />
                    }
                  </View>
                  {index === currentIndex && (
                    <View>
                      <Text style={[styles.subTextStyle]}>
                        {answer}
                      </Text>
                    </View>
                  )}
                </Pressable>
              </View>
            );
          })}
        </ScrollView>
      </View>
    </View>
  );
}

export default AIAnalysis

const styles = StyleSheet.create({
  container: {
    backgroundColor: constants.PRIMARY_DARK,
    flex: 1,
    margin: 12,
  },
  cardContainer: {
    flexGrow: 1,
  },
  card: {
    borderRadius: 9,
    borderWidth: 3,
    borderColor: 'black',
    paddingTop: 10,
    paddingLeft: 15,
    paddingRight: 15,
    paddingBottom: 12,
    elevation: 2,
    flexGrow: 1,
    marginBottom: 1,
  },
  button: {
    flexDirection: 'row',
    width: "98%",
    height: 85,
    borderBottomColor: 'gray',
    borderBottomWidth: .2,
    textAlignVertical: 'center',
    alignItems: 'center',
    paddingTop: 20
  },
  trackedButtons: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
  buttonOpen: {
    backgroundColor: "white",
  },
  buttonClose: {
    backgroundColor: constants.SECONDARY_DARK,
  },
  textStyle: {
    color: constants.TITLE_COLOR,
    opacity: .9,
    fontSize: 15,
    fontWeight: "bold",
    fontFamily: 'Verdana',
  },
  subTextStyle: {
    flexGrow: 0,
    color: constants.SUBTEXT_COLOR,
    width: "90%",
    marginLeft: 18,
    fontSize: 14,
    fontFamily: 'Verdana',
  },
  heading: {
    fontSize: 20,
    fontFamily: 'Verdana',
    color: constants.TITLE_COLOR,
    fontWeight: '600',
    marginBottom: 15,
  },
  body: {
    fontSize: 16,
    fontFamily: 'Verdana',
    color: constants.TITLE_COLOR,
    marginBottom: 15,
  },
  answerList: {
    marginTop: 10,
  },
});