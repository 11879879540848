import React from 'react';
import { Modal, View, Text, TextInput, TouchableOpacity, TouchableWithoutFeedback, StyleSheet, Dimensions } from 'react-native';
import { AntDesign } from '@expo/vector-icons';
import constants from '../../utils/constants';

const { width: screenWidth } = Dimensions.get("window");

const BillNotesModal = ({ visible, onClose, onSave, value, setValue }) => {

    const handleSave = () => {
        onSave();
        onClose(); // Close the modal
    };

    return (
        <Modal
            animationType="fade"
            transparent={true}
            visible={visible}
            onRequestClose={onClose}
        >
            <TouchableWithoutFeedback onPress={onClose}>
                <View style={styles.modalContainer}>
                    <TouchableWithoutFeedback>
                        <View style={[
                            styles.modalContent,
                            { width: screenWidth > 600 ? '35%' : '90%' } // Responsive width
                        ]}>
                            {/* Close Icon */}
                            <TouchableOpacity onPress={onClose} style={styles.closeButton}>
                                <AntDesign name="close" size={22} color={constants.ICON_COLOR} />
                            </TouchableOpacity>
                            <Text style={styles.modalTitle}>Add Notes</Text>
                            <TextInput
                                style={styles.textInput}
                                placeholder="Write your notes here..."
                                placeholderTextColor="#a9a9a9"
                                value={value}
                                onChangeText={setValue}
                                multiline={true}
                                textAlignVertical="top"
                            />
                            <TouchableOpacity onPress={handleSave} style={styles.saveButton}>
                                <Text style={styles.saveButtonText}>Save</Text>
                            </TouchableOpacity>
                        </View>
                    </TouchableWithoutFeedback>
                </View>
            </TouchableWithoutFeedback>
        </Modal>
    );
};

export default BillNotesModal;

const styles = StyleSheet.create({
    modalContainer: {
        flex: 1,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        justifyContent: 'center',
        alignItems: 'center',
    },
    modalContent: {
        maxHeight: '80%',
        minHeight: '40%',
        backgroundColor: constants.SECONDARY_DARK,
        borderRadius: 10,
        padding: 8,
        width: screenWidth > 600 ? '35%' : '90%', // Responsive width
        justifyContent: 'space-between', // Ensures elements are spaced proportionally
    },
    closeButton: {
        position: 'absolute',
        top: 10,
        right: 15,
        zIndex: 1,
    },
    modalTitle: {
        fontSize: 20,
        fontWeight: 'bold',
        color: '#e5e5e5',
        textAlign: 'center',
        marginVertical: '3%', // Proportional vertical spacing
    },
    textInput: {
        backgroundColor: '#1b1c1c',
        color: '#e5e5e5',
        borderRadius: 5,
        padding: 10,
        fontSize: 16,
        flexGrow: 1, // Allows dynamic resizing
        minHeight: 180, // Ensures a reasonable default size
        marginBottom: 10,
        borderColor: '#444',
        borderWidth: 1,
        textAlignVertical: 'top',
    },
    saveButton: {
        backgroundColor: constants.ICON_COLOR,
        borderRadius: 5,
        paddingVertical: 10, // Consistent padding
        alignItems: 'center',
    },
    saveButtonText: {
        color: '#fff',
        fontSize: 16,
        fontWeight: 'bold',
    },
});