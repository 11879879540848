import { db } from '../../db/firestore';
import { doc, getDoc, getDocs, collection, query, where, documentId } from "firebase/firestore";
import constants from '../../utils/constants';
import { StyleSheet, Text, View, Pressable, Platform } from 'react-native'
import { EvilIcons, Ionicons, AntDesign, MaterialIcons, FontAwesome } from '@expo/vector-icons';

const weekdayArr = ['Sun', 'Mon', 'Tues', 'Wed', 'Thur', 'Fri', 'Sat'];
const monthNameArr = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec',];

const getEvents = async (type) => {
    const docRef = collection(db, `${type}`);
    const querySnapshot = await getDocs(docRef)
    const eventData = await querySnapshot.docs.map((doc) => {
        const docEvent = doc.data()

        // Convert UTC to local time
        const startTimeUTC = docEvent.start ? new Date(docEvent.start) : new Date("2030-01-01T09:00:41Z");
        const localDate = startTimeUTC.toLocaleDateString();
        const localTime = startTimeUTC.toLocaleString([], { hour: '2-digit', minute: '2-digit', hour12: true });

        return {
            subject: docEvent.subject,
            noDate: docEvent.noDate ? docEvent.noDate : docEvent.start ? false : true,
            uponAdjourn: docEvent.uponAdjourn ? docEvent.uponAdjourn : false,
            adjournText: docEvent.adjournText ? docEvent.adjournText : null,
            start: docEvent.start ? docEvent.start : null,
            date: localDate,
            time: localTime,
            id: docEvent.id,
            location: docEvent.location,
            chamber: docEvent.chamber,
            billsResolutions: docEvent.billsResolutions,
            committees: docEvent.committees,
        };
    });
    return eventData
}

const chunkArray = (array, size) => {
    const chunks = [];
    for (let i = 0; i < array.length; i += size) {
        chunks.push(array.slice(i, i + size));
    }
    return chunks;
}

const getTrackedEvents = async (data) => {
    const eventRef = collection(db, "trackedEvents");
    const chunkedEventIds = chunkArray(data.allTrackedEvents, 30); // Split into chunks of 30
    const events = []; // Array to store results

    for (const chunk of chunkedEventIds) {
        const q = query(eventRef, where(documentId(), 'in', chunk));
        const querySnapshot = await getDocs(q);

        querySnapshot.forEach((doc) => {
            const docEvent = doc.data();
            let fromTrackedBills = false;
            let fromTrackedLanguage = false;
            if (Array.isArray(docEvent.billsResolutions)) {
                fromTrackedBills = docEvent.billsResolutions.some((bill) => data.trackedLegislationTitles.includes(bill));
                fromTrackedLanguage = docEvent.billsResolutions.some((bill) =>
                    data.trackedLanguageBills.some(tracked => tracked.bill === bill)
                );
            }

            // Convert UTC to local time
            const startTimeUTC = docEvent.start ? new Date(docEvent.start) : new Date("2030-01-01T09:00:41Z");
            const localDate = startTimeUTC.toLocaleDateString();
            const localTime = startTimeUTC.toLocaleString([], { hour: '2-digit', minute: '2-digit', hour12: true });

            const eventObject = {
                subject: docEvent.subject,
                noDate: docEvent.noDate ? docEvent.noDate : docEvent.start ? false : true,
                uponAdjourn: docEvent.uponAdjourn ? docEvent.uponAdjourn : false,
                adjournText: docEvent.adjournText ? docEvent.adjournText : null,
                start: docEvent.start ? docEvent.start : null,
                date: localDate,
                time: localTime,
                id: docEvent.id,
                location: docEvent.location,
                chamber: docEvent.chamber,
                billsResolutions: docEvent.billsResolutions,
                committees: docEvent.committees,
                fromTrackedBills: fromTrackedBills,
                fromTrackedLanguage: fromTrackedLanguage,
            }
            events.push(eventObject); // Add document data with ID
        });
    }
    return events
}

const sortEventsByChamber = (events) => {
    const houseArr = [];
    const senateArr = [];
    if (events) {
        for (let i = 0; i < events.length; i += 1) {
            if (events[i].chamber === 1) {
                houseArr.push(events[i]);
            } else if (events[i].chamber === 2) {
                senateArr.push(events[i])
            }
        }
    }
    return {
        houseArr,
        senateArr,
    }
}

const fillTable = (eventData, navigation, page) => {
    const dataArr = [];
    if (Array.isArray(eventData)) {
        for (let i = 0; i < eventData.length; i += 1) {
            let dateTimeString;
            let noStart = false;
            if (eventData[i].noDate && eventData[i].start == null) {
                dateTimeString = new Date("2030-08-21T13:32:06Z")
                noStart = true
            } else {
                dateTimeString = new Date(eventData[i].start);
            }
            const nameArr = [];
            if (eventData[i].committees) {
                for (let j = 0; j < eventData[i].committees.length; j += 1) {
                    nameArr.push(
                        <Text key={j} style={styles.textStyle}>{eventData[i].committees[j]}</Text>
                    )
                }
            }
            const weekday = weekdayArr[dateTimeString.getDay()];
            const month = monthNameArr[dateTimeString.getMonth()];
            const dateNum = dateTimeString.getDate();
            let formattedTime = eventData[i].time;
            const isNoDate = eventData[i].noDate ? eventData[i].noDate : false;
            const isUponAdjourn = eventData[i].uponAdjourn ? eventData[i].uponAdjourn : false;
            let time;
            if (isNoDate) {
                formattedTime = "(TBD)";
            }
            if (isUponAdjourn) {
                formattedTime = eventData[i].adjournText ? `${eventData[i].adjournText}` : "(TBD)";
            }
            const eventObj = (
                <Pressable
                    key={i}
                    style={[styles.button, styles.buttonClose]}
                    onPress={() =>
                        navigation.navigate('Meeting', {
                            title: "Meeting Info",
                            id: eventData[i].id,
                            page: page,
                            options: { headerBackTitleVisible: false },
                        })
                    }
                >
                    <View style={styles.rowContainer}>
                        <View style={[styles.calendarContainer]}>
                            <View style={styles.calendarMonth}>
                                <Text style={styles.monthText}>{noStart ? "No" : month}</Text>
                            </View>
                            <View style={[styles.calendarDate]}>
                                <Text style={styles.weekdayText}>{noStart ? null : weekday}</Text>
                                <Text
                                    style={[
                                        styles.dateText,
                                        { fontSize: noStart ? 11.5 : 16, marginTop: noStart ? 10 : 0 },
                                    ]}
                                >
                                    {noStart ? 'Date' : dateNum}
                                </Text>
                            </View>
                            <View style={{ ...styles.iconsContainer, display: (page === 'trackedEvents') ? 'flex' : 'none' }}>
                                {eventData[i].fromTrackedLanguage ? <FontAwesome name="key" size={22} color={constants.ICON_COLOR} /> : null}
                                {eventData[i].fromTrackedBills ? <AntDesign name="filetext1" size={22} color={constants.ICON_COLOR} style={{ marginLeft: 4 }} /> : null}
                            </View>
                        </View>
                        <View style={{ width: '98%' }}>
                            {page === 'trackedEvents' ? (
                                nameArr
                            ) : (
                                <Text style={styles.textStyle}>{eventData[i].subject}</Text>
                            )}
                            <View style={styles.rowContainer}>
                                <MaterialIcons
                                    name="subject"
                                    size={20}
                                    color="#e5e5e5"
                                    style={{ marginTop: 3, marginBottom: 0, marginRight: 3, marginLeft: 3 }}
                                />
                                <Text style={{ ...styles.subjectText }}>{eventData[i].subject}</Text>
                            </View>
                            <View style={styles.rowContainer}>
                                <EvilIcons
                                    name="location"
                                    size={22}
                                    color="#e5e5e5"
                                    style={{ marginTop: 3, marginBottom: 4, marginRight: 2 }}
                                />
                                <Text style={{ ...styles.locationText }}>{eventData[i].location}</Text>
                            </View>
                            {isNoDate ? null : (
                                <View style={styles.rowContainer}>
                                    <Ionicons
                                        name="time-outline"
                                        size={17}
                                        color="#e5e5e5"
                                        style={{ marginTop: -1.5, marginLeft: 4, marginRight: 3 }}
                                    />
                                    <Text style={{ ...styles.subTextStyle, marginTop: 0 }}>
                                        {formattedTime}
                                    </Text>
                                </View>
                            )}
                        </View>
                    </View>
                </Pressable>
            );
            dataArr.push(eventObj)
        }
        if (dataArr.length > 0) {
            return dataArr
        } else {
            return (
                <View style={[styles.rowContainer, { alignSelf: 'center', width: '98%', margin: 30 }]}>
                    <AntDesign name="exclamationcircleo" size={20} color={constants.ICON_COLOR} />
                    <Text style={styles.noEntriesText}> There are no entries for your selection!</Text>
                </View>
            )
        }
    } else {
        return (
            <View style={[styles.rowContainer, { alignSelf: 'center', width: '98%', margin: 30 }]}>
                <AntDesign name="exclamationcircleo" size={20} color={constants.ICON_COLOR} />
                <Text style={styles.noEntriesText}> There are no entries for your selection!</Text>
            </View>
        )
    }
}

const getPastEvents = (data) => {
    const filteredData = data.filter(item => {
        let currentDate = item.start ? new Date(item.start) : new Date("2030-05-07T09:00:41Z");
        return currentDate < Date.now();
    });
    return filteredData.sort(function (a, b) {
        return new Date(b.start) - new Date(a.start);
    });
}

const getCurrentEvents = (data) => {
    const filteredData = data.filter(item => {
        let currentDate = item.start ? new Date(item.start) : new Date("2030-05-07T09:00:41Z");
        return currentDate >= Date.now();
    });
    return filteredData.sort(function (a, b) {
        return new Date(a.start) - new Date(b.start);
    });
}

const styles = StyleSheet.create({
    container: {
        backgroundColor: constants.PRIMARY_DARK,
        marginTop: 20,
    },
    dropdown: {
        backgroundColor: '#838383',
        height: 40,
        width: 170,
        borderColor: constants.ICON_COLOR,
        borderWidth: 0.5,
        borderRadius: 8,
        paddingHorizontal: 8,
        marginBottom: 10,
    },
    listItems: {
        backgroundColor: "#D3D3D3",
        borderWidth: 1,
        marginLeft: 15,
        marginRight: 15,
        marginBottom: 6,
    },
    scrollView: {
        marginBottom: 100
    },
    button: {
        borderRadius: 9,
        borderWidth: 3,
        borderColor: 'black',
        paddingTop: 10,
        paddingLeft: 15,
        paddingRight: 15,
        paddingBottom: 12,
        elevation: 2,
        alignSelf: 'center',
        width: "98%",
        flexGrow: 1,
        marginBottom: 1,
    },
    trackedButtons: {
        flexDirection: 'row',
        justifyContent: 'center',
    },
    buttonOpen: {
        backgroundColor: "white",
    },
    buttonClose: {
        backgroundColor: constants.SECONDARY_DARK,
    },
    calendarContainer: {
        backgroundColor: 'white',
        width: 48.5,
        borderColor: 'black',
        borderWidth: 2.65,
        borderRadius: 5,
        marginBottom: 10,
        marginRight: 7,
        marginTop: 4,
        height: 53.5,
    },
    calendarMonth: {
        backgroundColor: '#D21F3C',
        height: 18,
        borderColor: 'gray',
        borderWidth: .09,
        borderBottomColor: 'black',
        borderBottomWidth: 1.6,
        borderTopRightRadius: 2.5,
        borderTopLeftRadius: 2.5,
        alignItems: 'center',
    },
    calendarDate: {
        alignItems: 'center',
        marginBottom: 14,
    },
    monthText: {
        color: 'white',
        fontWeight: 'bold',
        fontFamily: 'Verdana',
        fontSize: 12
    },
    weekdayText: {
        color: "#373737",
        marginBottom: -4,
        fontSize: 11,
        fontWeight: "bold",
    },
    dateText: {
        fontWeight: 'bold',
        fontFamily: 'Verdana',
        fontSize: 16,
    },
    subjectText: {
        color: "#e5e5e5",
        marginTop: 3,
        marginBottom: 0,
        fontSize: 12,
        fontWeight: "bold",
    },
    locationText: {
        color: "#e5e5e5",
        marginTop: 3,
        marginBottom: 6,
        fontSize: 11,
        fontWeight: "bold",
    },
    textStyle: {
        color: constants.TITLE_COLOR,
        marginBottom: 2,
        marginLeft: 2,
        fontSize: 14,
        fontWeight: "bold",
        textAlignVertical: "center",
        ...Platform.select({
            ios: {
                lineHeight: 20
            },
            android: {}
        })
    },
    subTextStyle: {
        color: constants.SUBTEXT_COLOR,
        marginBottom: 0,
        fontSize: 11,
        fontWeight: "bold",
    },
    noEntriesText: {
        color: 'white',
        fontSize: 14,
        fontWeight: "bold",
        fontFamily: 'Verdana',
        textAlign: 'center',
        textAlignVertical: "center",
        ...Platform.select({
            ios: {
                lineHeight: 20
            },
            android: {}
        })
    },
    rowContainer: {
        flexDirection: 'row',
        marginBottom: 2,
        flexGrow: 1,
        width: "90%",
    },
    iconsContainer: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 5, // Space above the icons
    },
    icon: {
        color: 'white',
        marginRight: 5,
    },
    label: {
        position: 'absolute',
        backgroundColor: 'white',
        left: 22,
        top: 8,
        zIndex: 999,
        paddingHorizontal: 8,
        fontSize: 14,
    },
    placeholderStyle: {
        fontSize: 16,
    },
    selectedTextStyle: {
        fontSize: 16,
    },
    iconStyle: {
        width: 20,
        height: 20,
    },
    inputSearchStyle: {
        height: 40,
        fontSize: 16,
    },
})


export {
    getEvents,
    getTrackedEvents,
    fillTable,
    getPastEvents,
    getCurrentEvents,
    sortEventsByChamber,
}